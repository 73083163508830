var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c("nav", { staticClass: "navbar navbar-expand-lg" }, [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "div",
            { staticClass: "flex-grow-1 me-2" },
            [
              !_vm.showAppBtnBack && _vm.showCategories()
                ? _c(
                    "button",
                    {
                      staticClass: "navbar-toggler",
                      attrs: {
                        type: "button",
                        "aria-expanded": _vm.drawer ? "true" : "false",
                        "aria-label": "Toggle navigation",
                      },
                      on: {
                        click: function ($event) {
                          _vm.drawer = !_vm.drawer
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "icon-bars",
                        class: { "icon-bars-x": _vm.drawer },
                      }),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "navbar-toggler",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.back()
                        },
                      },
                    },
                    [
                      _c("base-icon", {
                        staticClass: "icon-lg",
                        attrs: { name: "arrow-left" },
                      }),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("logo", { staticClass: "navbar-brand d-none d-lg-block" }),
            ],
            1
          ),
          _vm._v(" "),
          _c("the-header-nav-quick", { attrs: { links: _vm.links } }),
          _vm._v(" "),
          _vm.$store.state.ui.switch.web.header.formSearch
            ? [_c("the-header-search")]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "the-header-menu",
        on: {
          click: function ($event) {
            return _vm.onClickTheHeaderMenu($event)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _vm.showCategories()
              ? _c("the-header-categories", {
                  attrs: { links: _vm.links },
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }